interface HttpClientResponse<R> {
	readonly ok?: boolean;
	readonly payload?: R;
	readonly error?: string;
}

export const httpClient = async <T, R = Record<string, string>>(
	svc: string,
	data: T
): Promise<HttpClientResponse<R>> => {
	try {
		const response = await fetch(svc, {
			method: "POST",
			body: JSON.stringify(data)
		});

		const payload = (await response.json()) as R;

		return { ok: response.ok, payload };
	} catch (error) {
		const { message } = error as Error;
		return { error: message };
	}
};
