import { useState } from "preact/hooks";

//
//
//

export const CookieBanner = () => {
	const [hide, setHide] = useState(false);
	const [accept, setAcceptt] = useState(false);

	if (hide) {
		return null;
	}

	const handleAccept = (state: boolean) => () => {
		setAcceptt(state);
		setHide(true);
	};

	return (
		<div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
			<div className="pointer-events-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
				<p className="text-sm leading-6 text-gray-900">
					This website uses cookies to ensure you get the best experience on our website.
					<br />
					<a href="https://akieni.academy" className="font-semibold text-indigo-600">
						Learn more
					</a>
					.
				</p>
				<div className="mt-4 flex items-center gap-x-5">
					<button
						type="button"
						onClick={handleAccept(true)}
						className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
					>
						Accept all
					</button>
					<button
						type="button"
						onClick={handleAccept(false)}
						className="text-sm font-semibold leading-6 text-gray-900"
					>
						Reject all
					</button>
					<div class="hidden">{accept}</div>
				</div>
			</div>
		</div>
	);
};
