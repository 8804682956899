import { h } from "preact";

//
//
//

interface ImageProps {
	src: string;
	alt: string;
	width?: number | string;
	height?: number | string;
	className: string;
	[key: string]: unknown;
}

const Image = ({ src, alt, width, height, ...props }: ImageProps): h.JSX.Element => (
	<img src={src} alt={alt} width={width} height={height} loading="lazy" {...props} />
);

export default Image;
