/** @jsxImportSource preact */
import { h } from "preact";

import { useState } from "preact/hooks";
import { Application } from "./application";
import { CookieBanner } from "./cookie_banner";
import Image from "./image";

//
//
//

// { name: "Product", href: "#" },

interface Navigation {
	readonly name: string;
	readonly href: string;
}

const navigation: Array<Navigation> = [
	{ name: "About Academy", href: "#about-academy" },
	{ name: "About Akieni", href: "#about-akieni" },
	{ name: "FAQs", href: "#faqs" }
];

//
//
//

interface IconProps {
	readonly className: string;
}

const AcademicCapIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke="currentColor"
			{...props}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
			/>
		</svg>
	);
};

export const XMarkIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		{...props}
	>
		<path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
	</svg>
);

const Bars3Icon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
		/>
	</svg>
);

const FaceSmileIcon = (props: IconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z"
		/>
	</svg>
);

const ArrowTrendingUpIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke="currentColor"
			{...props}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
			/>
		</svg>
	);
};

const UserGroupIcon = (props: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke="currentColor"
			{...props}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
			/>
		</svg>
	);
};

//
//
//

const features = [
	{
		name: "Upskilling the Youth",
		description:
			"Providing high-quality tech education to equip young people with in-demand skills.",
		icon: AcademicCapIcon
	},
	{
		name: "Job Placement",
		description:
			"Helping graduates find employment in the tech industry, reducing unemployment rates.",
		icon: FaceSmileIcon
	},
	{
		name: "Economic Development",
		description:
			"Contributing to the country’s and continent's technological growth and economic prosperity.",
		icon: ArrowTrendingUpIcon
	},
	{
		name: "Community Impact",
		description:
			"Creating a tech-savvy community ready to tackle modern challenges and innovate.",
		icon: UserGroupIcon
	}
];
const programs = [
	{
		name: "Python Development & Data Science",
		id: "Python Development & Data Science",
		description: "Gain skills in Python, web development, data analysis, and machine learning.",
		features: [
			"Python Programming Fundamentals",
			"Advanced Python Concepts",
			"Web Development with Flask/Django",
			"APIs, Testing, and Deployment",
			"Introduction to Data Science and Machine Learning",
			"Capstone Project and Career Preparation"
		],
		mostPopular: false
	},
	{
		name: "Full Stack Development",
		id: "Full Stack Development",
		description: "Learn Node.js and React.js to become a versatile developer.",
		features: [
			"Fundamentals of Web Development & JavaScript/Typescript Deep Dive",
			"Front-End Development with React.js",
			"Back-End Development with Node.js",
			"Databases and Authentication",
			"Full Stack Integration",
			"Capstone Project & Career Preparation"
		],
		mostPopular: true
	},
	{
		name: "UX/UI Design",
		id: "UX/UI Design",
		description:
			"Master user experience and interface design to create user-centric digital experiences.",
		features: [
			"Introduction to UX/UI Design",
			"Research and User Analysis",
			"Design Tools and Techniques",
			"Usability Testing and Iteration",
			"Advanced UX/UI Concepts",
			"Capstone Project and Career Preparation"
		],
		mostPopular: false
	}
];

//
//
//

const faqs = [
	{
		id: 1,
		question: "What are the eligibility criteria for applying?",
		answer: "Candidates must have a high school diploma or equivalent, a basic understanding of computers and coding, and proficiency in English. Residency in the Republic of the Congo is required, but we may accept some applications from CEEAC countries."
	},
	{
		id: 2,
		question: "Is the course paid or free?",
		answer: "The courses at Akieni Academy are completely free. Our mission is to provide accessible and high-quality education to help bridge the skills gap and foster innovation in the Republic of the Congo and Central Africa. There are no fees or charges for enrolling in our programs, and Akieni does not pay students a tuition either."
	},
	{
		id: 3,
		question: "What is the application process?",
		answer: "The application process involves submitting an online application form, taking a pre-assessment test, participating in an interview, and providing required documents."
	},
	{
		id: 4,
		question: "How long is each program?",
		answer: "Each program lasts for 6 months and includes online coursework and practical projects."
	},
	{
		id: 5,
		question: "How is the training structured and what is required of students each week?",
		answer: "The training at Akieni Academy is designed to be flexible and accessible. Each week, students are provided with a set of topics and learning materials that they need to go through. These materials can include video lectures, readings, and interactive exercises. At the end of each week, there may be an assessment or quiz to test your understanding of the topics covered. Students are expected to complete these assessments to track their progress and ensure they are meeting the learning objectives. This structure allows you to learn at your own pace while ensuring you stay on track with the course requirements."
	},
	{
		id: 6,
		question: "What support do students receive?",
		answer: "Students receive continuous evaluation through quizzes and assignments, mentorship and guidance from dedicated mentors, and online assessments to track progress."
	},
	{
		id: 7,
		question: "What are the requirements for graduation?",
		answer: "Students must successfully complete all online modules and assignments, achieve minimum scores in the online assessments, complete a capstone project, and actively participate in study groups and mentoring sessions."
	},
	{
		id: 8,
		question: "Do I need a computer to take the course?",
		answer: "Yes, you will need a computer with internet access to take the course. The coursework involves online materials, interactive exercises, and assessments that require a computer."
	}
];

//
//
//
// TODO remove this
export const footerNavigation = {
	solutions: [
		{ name: "Hosting", href: "#" },
		{ name: "Data Services", href: "#" },
		{ name: "Uptime Monitoring", href: "#" },
		{ name: "Enterprise Services", href: "#" }
	],
	support: [
		{ name: "Pricing", href: "#" },
		{ name: "Documentation", href: "#" },
		{ name: "Guides", href: "#" },
		{ name: "API Reference", href: "#" }
	],
	company: [
		{ name: "About", href: "#" },
		{ name: "Blog", href: "#" },
		{ name: "Jobs", href: "#" },
		{ name: "Press", href: "#" },
		{ name: "Partners", href: "#" }
	],
	legal: [
		{ name: "Claim", href: "#" },
		{ name: "Privacy", href: "#" },
		{ name: "Terms", href: "#" }
	]
};

const classNames = (...classes: Array<string>) => classes.filter(Boolean).join(" ");

//
//
//

interface Props {
	readonly year: number;
	readonly requestId: string;
	readonly url: string;
}

export const Main = ({ year, requestId, url }: Props): h.JSX.Element => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	const [open, setOpen] = useState(false);
	const [program, setProgram] = useState<string | null>(null);

	const handleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

	return (
		<div className="bg-white">
			<header className="absolute inset-x-0 top-0 z-50">
				<nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
					<div className="flex place-content-center lg:flex-1">
						<h1 className="sr-only text-2xl font-semibold leading-7 text-indigo-600">
							Akieni Academy
						</h1>

						<Image
							alt=""
							className="h-7 w-auto"
							src="/assets/akieni_academy_logo.png"
						/>
					</div>
					<div className="flex lg:hidden">
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={handleMobileMenu}
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="hidden lg:flex lg:gap-x-12">
						{navigation.map((item) => (
							<a
								key={item.name}
								href={item.href}
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								{item.name}
							</a>
						))}
					</div>
					<div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
				</nav>

				{mobileMenuOpen ? (
					<div class="lg:hidden" role="dialog" aria-modal="true">
						<div class="fixed inset-0 z-50"></div>
						<div class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
							<div class="flex items-center justify-between">
								<a href="#" class="-m-1.5 p-1.5">
									<span class="sr-only">Akieni Academy</span>
								</a>
								<button
									type="button"
									class="-m-2.5 rounded-md p-2.5 text-gray-700"
									onClick={handleMobileMenu}
								>
									<span class="sr-only">Close menu</span>
									<svg
										class="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>
							</div>
							<div class="mt-6 flow-root">
								<div class="-my-6 divide-y divide-gray-500/10">
									<div class="space-y-2 py-6">
										{navigation.map((item) => (
											<a
												onClick={handleMobileMenu}
												key={item.name}
												href={item.href}
												className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
											>
												{item.name}
											</a>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</header>

			<main className="isolate">
				<Application
					open={open}
					setOpen={setOpen}
					program={program || ""}
					requestId={requestId}
					url={url}
				/>

				{/* Hero section */}
				<div className="relative pt-14">
					<div
						className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
						aria-hidden="true"
					>
						<div
							className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
							style={{
								clipPath:
									"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
							}}
						/>
					</div>
					<div className="py-24">
						<div className="mx-auto max-w-7xl px-6 lg:px-8">
							<div className="mx-auto max-w-6xl text-center">
								<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
									Empowering Minds, Shaping Futures
								</h1>
								<p className="mt-6 text-lg leading-8 text-gray-600">
									Join Akieni Academy to bridge the skills gap and drive
									innovation in Central Africa.
								</p>
								<div className="mt-10 flex items-center justify-center gap-x-6">
									<a
										href="#our-programs"
										className="rounded-md bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Apply
									</a>
									<a
										href="#about-academy"
										className="text-sm font-semibold leading-6 text-gray-900"
									>
										Learn more <span aria-hidden="true">→</span>
									</a>
								</div>
							</div>
							<div className="mt-16 flow-root sm:mt-24">
								<div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 contrast-75 lg:-m-4 lg:rounded-2xl lg:p-4">
									<Image
										src="/assets/img_1.jpg"
										alt="Akieni Academy"
										width={2432}
										height={1442}
										className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
						aria-hidden="true"
					>
						<div
							className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
							style={{
								clipPath:
									"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
							}}
						/>
					</div>
				</div>

				<section id="about-us">
					<div className="mx-auto max-w-7xl px-6 sm:pt-6 lg:px-8">
						<div className="mx-auto max-w-lg gap-y-12 sm:max-w-xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-20 lg:gap-y-14">
							<div className="mx-auto max-w-2xl lg:mx-0">
								<h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
									Our Mission
								</h2>
								<p className="mt-6 text-lg leading-8 text-gray-600">
									To transform lives through technology education, bridging the
									skills gap and fostering innovation in the Republic of the Congo
									and across Central Africa.
								</p>
							</div>
							<div className="mx-auto max-w-2xl lg:mx-0">
								<h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
									Our Vision
								</h2>
								<p className="mt-6 text-lg leading-8 text-gray-600">
									To be a leading institution in tech education, empowering
									individuals to thrive in a digital world and contribute to the
									economic development of their communities.
								</p>
							</div>
						</div>
					</div>
				</section>

				<section id="about-academy">
					<div className="mx-auto mt-32 max-w-7xl px-6">
						<div className="mx-auto max-w-4xl lg:text-center">
							<h2 className="text-base font-semibold leading-7 text-indigo-600">
								About Akieni Academy
							</h2>

							<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
								Empowering the Future
							</p>
							<p className="mt-6 text-lg leading-8 text-gray-600">
								Akieni Academy was founded with the goal of providing high-quality
								technology education to bridge the skills gap and foster innovation.
								We offer a variety of programs designed to equip students with the
								necessary skills to thrive in the tech industry. Our academy
								combines online learning with practical, hands-on projects to ensure
								that students are well-prepared for their future careers. We are
								dedicated to supporting our students through continuous evaluation,
								mentorship, and a community-driven approach.
							</p>
						</div>
						<div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
							<dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
								{features.map((feature) => (
									<div key={feature.name} className="relative pl-16">
										<dt className="text-base font-semibold leading-7 text-gray-900">
											<div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
												<feature.icon
													className="h-6 w-6 text-white"
													aria-hidden="true"
												/>{" "}
											</div>
											{feature.name}
										</dt>
										<dd className="mt-2 text-base leading-7 text-gray-600">
											{feature.description}
										</dd>
									</div>
								))}
							</dl>
						</div>
					</div>
				</section>

				<section id="empower">
					<div className="mx-auto mt-32 max-w-7xl px-6">
						<div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
							<Image
								className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
								src="/assets/image_tech.jpg"
								alt="Akieni Academy"
							/>
							<div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
							<div
								className="absolute -left-80 -top-56 transform-gpu blur-3xl"
								aria-hidden="true"
							>
								<div
									className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-[0.45]"
									style={{
										clipPath:
											"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
									}}
								/>
							</div>
							<div
								className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
								aria-hidden="true"
							>
								<div
									className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
									style={{
										clipPath:
											"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
									}}
								/>
							</div>
							<div className="relative mx-auto max-w-2xl lg:mx-0">
								<Image
									className="h-16 w-auto"
									src="/assets/africa_globe_icon.svg"
									alt=""
									loading="eager"
								/>
								<br />
								<br />
								<figure>
									<blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
										<p>"Empowering the next generation of tech leaders. </p>
										<p>
											At Akieni Academy, we are dedicated to bridging the
											skills gap and fostering innovation in the Republic of
											the Congo and across Central Africa."
										</p>
									</blockquote>
									<figcaption className="mt-6 text-base text-white">
										<div className="font-semibold">
											Join us in shaping a brighter future.
										</div>
									</figcaption>
								</figure>
							</div>
						</div>
					</div>
				</section>

				<section id="our-programs">
					<div className="mx-auto mt-32 max-w-7xl px-6">
						<div className="mx-auto">
							<div className="mx-auto max-w-7xl lg:text-center">
								<h2 className="text-base font-semibold leading-7 text-indigo-600">
									Our Programs
								</h2>
								<p className="mt-2 text-4xl font-bold tracking-tight text-gray-800 sm:text-5xl">
									Unlock Your Potential with Cutting-Edge Tech Education
								</p>
							</div>
							<p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 lg:text-center">
								Discover our comprehensive 6-month programs designed to equip you
								with in-demand tech skills through a blend of online coursework and
								practical projects.
							</p>
							<div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
								{programs.map((tier, tierIdx) => (
									<div
										key={tier.id}
										className={classNames(
											tier.mostPopular
												? "lg:z-10 lg:rounded-b-none"
												: "lg:mt-8",
											tierIdx === 0 ? "lg:rounded-r-none" : "",
											tierIdx === programs.length - 1
												? "lg:rounded-l-none"
												: "",
											"flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
										)}
									>
										<div>
											<div className="flex items-center justify-between gap-x-4">
												<h3
													id={tier.id}
													className={classNames(
														tier.mostPopular
															? "text-gray-900"
															: "text-gray-900",
														"text-lg font-semibold leading-8"
													)}
												>
													{tier.name}
												</h3>
											</div>
											<p className="mt-4 text-sm leading-6 text-gray-600">
												{tier.description}
											</p>
											<ul
												role="list"
												className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
											>
												{tier.features.map((feature) => (
													<li key={feature} className="flex gap-x-3">
														<svg
															class="h-6 w-5 flex-none text-indigo-600"
															viewBox="0 0 20 20"
															fill="currentColor"
															aria-hidden="true"
														>
															<path
																fill-rule="evenodd"
																d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
																clip-rule="evenodd"
															/>
														</svg>{" "}
														{feature}
													</li>
												))}
											</ul>
										</div>
										<button
											onClick={() => {
												setProgram(tier.id);
												setOpen(!open);
											}}
											aria-describedby={tier.id}
											className={classNames(
												"bg-indigo-600 text-white shadow-sm hover:bg-indigo-500",
												"mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											)}
										>
											Apply
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>

				<section id="faqs">
					<div className="mx-auto mt-32 max-w-2xl divide-y divide-gray-900/10 px-6 lg:max-w-7xl">
						<h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
							Frequently asked questions
						</h2>
						<dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
							{faqs.map((faq) => (
								<div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
									<dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
										{faq.question}
									</dt>
									<dd className="mt-4 lg:col-span-7 lg:mt-0">
										<p className="text-base leading-7 text-gray-600">
											{faq.answer}
										</p>
									</dd>
								</div>
							))}
						</dl>
					</div>
				</section>

				<section id="about-akieni">
					<div className="mx-auto mt-32 max-w-7xl px-6">
						<div className="mx-auto max-w-7xl lg:text-center">
							<div className="mx-auto max-w-7xl">
								<h2 className="text-base font-semibold leading-7 text-indigo-600">
									About Akieni
								</h2>
								<p className="mt-2 text-4xl font-bold tracking-tight text-gray-800 sm:text-5xl">
									Driving Digital Transformation Across Central Africa
								</p>
							</div>
							<p className="mx-auto mt-6 max-w-5xl text-lg leading-8 text-gray-600 sm:text-center">
								Akieni is pioneering the digital transformation across Central
								Africa, providing innovative solutions and expert consulting to
								governments, businesses, and institutions, thus creating a seamless
								integration of digital services that drive efficiency, transparency,
								and sustainable growth.
							</p>

							<div className="-z-10 mt-2 px-6 sm:pt-6 sm:text-center lg:px-8">
								<div className="mt-2 flex items-center justify-center gap-x-6">
									<a
										target="_blank"
										href="https://akieni.com"
										className="text-sm font-semibold leading-6 text-gray-900"
									>
										Find more about Akieni <span aria-hidden="true">→</span>
									</a>
								</div>
							</div>
						</div>
						<div class="relative overflow-hidden pt-16 lg:pt-20">
							<div class="mx-auto max-w-7xl px-6 lg:px-8">
								<Image
									className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
									src="https://images.squarespace-cdn.com/content/v1/63ee48437031fd0a3e418ebf/c7885ed6-f697-40e5-ae8d-b70731113bf2/Akieni+Photo+081324+brand4.png"
									alt="Akieni"
								/>
								<div class="relative" aria-hidden="true">
									<div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="relative -z-10 mt-32 px-6 lg:px-8">
					<div
						className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
						aria-hidden="true"
					>
						<div
							className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
							style={{
								clipPath:
									"polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)"
							}}
						/>
					</div>
					<div className="mx-auto max-w-6xl text-center">
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
							Together, we can build a brighter, tech-driven tomorrow.
						</h2>
					</div>
					<div
						className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
						aria-hidden="true"
					>
						<div
							className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
							style={{
								clipPath:
									"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
							}}
						/>
					</div>
				</div>
			</main>

			{/* Footer */}
			<div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
				<footer
					aria-labelledby="footer-heading"
					className="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
				>
					<div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
						<p className="text-xs leading-5 text-gray-400"></p>
						<div class="container mx-auto px-4">
							<div class="flex flex-col items-center justify-between md:flex-row">
								<p class="mb-2 text-sm md:mb-0">
									&copy; {year.toString()} Akieni. All rights reserved.
								</p>
								<nav class="text-sm">
									<a
										href="/terms-and-conditions"
										target="_blank"
										class="mx-2 text-gray-400 hover:text-gray-200"
									>
										Terms and Conditions
									</a>
								</nav>
							</div>
						</div>
					</div>
				</footer>
			</div>
			<CookieBanner />
		</div>
	);
};
