import { Fragment, h } from "preact";
import { useEffect, useState, type Dispatch, type StateUpdater } from "preact/hooks";

import { phone } from "phone";
import { z, ZodSchema } from "zod";

import { isNumber } from "../../utils/is_number";
import { httpClient } from "../../utils/http_client";
import { ChevronDownIcon, XMarkIcon } from "./icons";

//
//
//

const MAX_CHARS = 1000;

//
//
//

const CEEAC_COUNTRIES = [
	{
		name: "Republic of the Congo",
		countryCode2: "CG",
		mobileCountryCode: "+242"
	},
	{
		name: "Republic of Angola",
		countryCode2: "AO",
		mobileCountryCode: "+244"
	},
	{
		name: "Republic of Burundi",
		countryCode2: "BI",
		mobileCountryCode: "+257"
	},
	{
		name: "Republic of Cameroon",
		countryCode2: "CM",
		mobileCountryCode: "+237"
	},
	{
		name: "Central African Republic",
		countryCode2: "CF",
		mobileCountryCode: "+236"
	},
	{
		name: "Republic of Chad",
		countryCode2: "TD",
		mobileCountryCode: "+235"
	},
	{
		name: "Democratic Republic of the Congo",
		countryCode2: "CD",
		mobileCountryCode: "+243"
	},

	{
		name: "Republic of Equatorial Guinea",
		countryCode2: "GQ",
		mobileCountryCode: "+240"
	},
	{
		name: "Republic of Gabon",
		countryCode2: "GA",
		mobileCountryCode: "+241"
	},
	{
		name: "Republic of Rwanda",
		countryCode2: "RW",
		mobileCountryCode: "+250"
	},
	{
		name: "Democratic Republic of São Tomé and Príncipe",
		countryCode2: "ST",
		mobileCountryCode: "+239"
	}
] as const;

const countryCodes = CEEAC_COUNTRIES.map((country) => country.mobileCountryCode);

const countryCodesTuple = countryCodes as [(typeof countryCodes)[0], ...typeof countryCodes];

//
//
//

const initialState = {
	program: "",
	firstName: "",
	lastName: "",
	email: "",
	country: "+242",
	phoneNumber: "",
	educationalBackground: "",
	motivationStatement: "",
	agreed: false,
	verificationCode: "",
	hmac: "",
	dob: 2008
};

type TInitialState = keyof typeof initialState;
type TApplication = typeof initialState;

const YEAR = new Date().getFullYear();

const ApplicationSchema = z.object({
	program: z.string().trim().min(1, "Program is missing"),
	firstName: z.string().trim().min(3, "First Name is required"),
	lastName: z.string().trim().min(3, "Last Name is required"),
	dob: z
		.number()
		.max(YEAR - 16, "Applicants must be at least 16 years old.")
		.min(YEAR - 30, "Applicants must be no older than 30 years old."),
	email: z.string().email({ message: "Invalid email address" }),
	country: z.enum(countryCodesTuple),
	phoneNumber: z
		.string()
		.trim()
		.refine(
			(value: string) => {
				const checkPhone = phone(value);
				return checkPhone.isValid;
			},
			{
				message: "Phone Number is not valid"
			}
		),
	educationalBackground: z
		.string()
		.trim()
		.min(100, "Education Background needs to have minimum 100 characters"),
	motivationStatement: z
		.string()
		.trim()
		.min(100, "Motivation Statement needs to have minimum 100 characters"),
	agreed: z.boolean({ required_error: "You need to agree to the terms." }),
	verificationCode: z.string(),
	hmac: z.string()
});

interface ZodErrors {
	readonly path: string;
	readonly message: string;
}

export const applyConditionalValidation = (schema: ZodSchema<TApplication>, data: TApplication) => {
	let s: ZodSchema<TApplication> = schema;

	if (data.verificationCode?.length) {
		s = ApplicationSchema.extend({
			verificationCode: z
				.string()
				.trim()
				.min(6, "Verification Code must be exactly 6 characters")
				.max(6)
		});
	}
	return s.parse(data);
};

const delay = (ms = 10) => new Promise((resolve) => setTimeout(resolve, ms));

//
//
//

interface VerificationCodeResponse {
	readonly hmac: string;
}

interface VerificationCodeRequest {
	readonly email: string;
	readonly phoneNumber: string;
	readonly clientRequestId: string;
}

//
//
//

interface Props {
	readonly open: boolean;
	readonly setOpen: Dispatch<StateUpdater<boolean>>;
	readonly program: string;
	readonly requestId: string;
	readonly url: string;
}

export const Application = ({ url, open, setOpen, program, requestId }: Props): h.JSX.Element => {
	if (!open) {
		return <></>;
	}

	//
	//
	//

	const [application, setApplication] = useState<TApplication>(initialState);
	const [showCode, setShowCode] = useState(false);
	const [disableCode, setDisableCode] = useState(false);
	const [errors, setErrors] = useState<Array<string>>([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	//
	//
	//

	const put = (prop: TInitialState, value: string | boolean | number) =>
		setApplication((curr) => ({ ...curr, [prop]: value }));

	//
	//
	//

	const handleCancel = () => {
		put("agreed", false);
		put("verificationCode", "");
		setDisableCode(false);
		setErrors([]);
		setOpen(false);
		setShowCode(false);
		setLoading(false);
	};

	//
	//
	//

	const handleSubmit = async (e: preact.JSX.TargetedEvent<HTMLFormElement, Event>) => {
		e.preventDefault();

		setLoading(true);
		setErrors([]);

		try {
			const data = {
				...application,
				phoneNumber: `${application.country}${application.phoneNumber}`,
				clientRequestId: requestId
			};
			ApplicationSchema.parse(data);

			await delay(1000);

			// trigger the verification code to the email
			const res = await httpClient<VerificationCodeRequest, VerificationCodeResponse>(
				`${url}/svcs/verification-code`,
				{
					email: application.email,
					clientRequestId: requestId,
					phoneNumber: data.phoneNumber
				}
			);

			put("hmac", res.payload?.hmac || "null");
			setShowCode(true);
		} catch (error) {
			const { message } = error as Error;
			const errorsData = JSON.parse(message || "") as Array<ZodErrors>;
			const messages = errorsData.map((entry) => `${entry.message}`);
			setErrors(messages);
			setLoading(false);
		}
	};

	const firstNameHandler = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) =>
		put("firstName", e.currentTarget.value);
	const lastNameHandler = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) =>
		put("lastName", e.currentTarget.value);
	const dobHandler = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) =>
		put("dob", +e.currentTarget.value);
	const emailHandler = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) =>
		put("email", e.currentTarget.value);
	const countryHandler = (e: h.JSX.TargetedEvent<HTMLSelectElement, Event>) =>
		put("country", e.currentTarget.value);
	const phoneNumberHandler = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) =>
		put("phoneNumber", e.currentTarget.value);
	const motivationStatementHandler = (e: h.JSX.TargetedEvent<HTMLTextAreaElement, Event>) =>
		put("motivationStatement", e.currentTarget.value);
	const educationalBackgroundHandler = (e: h.JSX.TargetedEvent<HTMLTextAreaElement, Event>) =>
		put("educationalBackground", e.currentTarget.value);
	const agreedHandler = () => put("agreed", !application.agreed);
	const codeHandler = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
		const { value } = e.currentTarget;
		put("verificationCode", isNumber(value) ? value : application.verificationCode);
	};

	//
	// tx application
	//

	useEffect(() => {
		put("program", program);
	}, []);

	useEffect(() => {
		if (application.verificationCode.length > 5) {
			setDisableCode(true);

			(async () => {
				await delay(1000);
				await httpClient(`${url}/svcs/application`, { ...application });
				setSubmitted(true);
			})();
		}
	}, [application.verificationCode]);

	return (
		<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
			<div
				class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
				aria-hidden="true"
			></div>

			<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<div class="md:max-w-1xl relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 lg:max-w-3xl">
						<div>
							<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
								<button
									type="button"
									onClick={handleCancel}
									className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									<span className="sr-only">Close</span>
									{!submitted ? (
										<XMarkIcon aria-hidden="true" className="h-6 w-6" />
									) : null}
								</button>
							</div>
						</div>

						{submitted ? (
							<SubmittedMessage dimiss={handleCancel} />
						) : (
							<div className="sm:flex sm:items-start">
								<div className="mt-3 text-center sm:mt-0 sm:text-left">
									<h3 className="mb-2 text-2xl font-semibold leading-6 text-gray-900">
										You are applying to the {program} Program
									</h3>

									<div class="rounded-md bg-yellow-50 p-4">
										<div class="flex">
											<div class="flex-shrink-0">
												<svg
													class="h-5 w-5 text-yellow-400"
													viewBox="0 0 20 20"
													fill="currentColor"
													aria-hidden="true"
												>
													<path
														fill-rule="evenodd"
														d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
														clip-rule="evenodd"
													/>
												</svg>
											</div>
											<div class="ml-3 mt-2 text-left">
												<h3 class="text-sm font-medium text-yellow-800">
													Attention needed
												</h3>
												<div class="mt-2 text-sm text-yellow-700">
													<p>
														Although we’re moving to the next stage for
														the current cohort, we encourage you to
														continue applying. Your application will be
														considered for the next session of the
														Akieni Academy.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											Complete the form below to apply to Akieni Academy.
											Ensure you meet the eligibility criteria and provide all
											required information.
										</p>

										<hr class="my-8 h-px border-0 bg-gray-200 dark:bg-gray-700" />

										<div
											aria-hidden="true"
											className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
										>
											<div
												style={{
													clipPath:
														"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
												}}
												className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
											/>
										</div>

										<form onSubmit={handleSubmit}>
											<div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
												<div>
													<label
														htmlFor="first-name"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														First name
													</label>
													<div className="mt-2.5">
														<input
															id="firstName"
															name="firstName"
															value={application.firstName}
															onChange={firstNameHandler}
															disabled={loading}
															type="text"
															autoComplete="given-name"
															className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div>
													<label
														htmlFor="last-name"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														Last name
													</label>
													<div className="mt-2.5">
														<input
															id="lastName"
															name="lastName"
															value={application.lastName}
															onChange={lastNameHandler}
															disabled={loading}
															type="text"
															autoComplete="family-name"
															className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>

												<div>
													<label
														htmlFor="dob"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														Year of Birth
													</label>
													<div className="mt-2.5">
														<input
															id="dob"
															name="dob"
															type="number"
															value={application.dob}
															onChange={dobHandler}
															disabled={loading}
															autoComplete="email"
															className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div>
													<label
														htmlFor="email"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														Email Address
													</label>
													<div className="mt-2.5">
														<input
															id="email"
															name="email"
															type="email"
															value={application.email}
															onChange={emailHandler}
															disabled={loading}
															autoComplete="email"
															className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div className="sm:col-span-2">
													<label
														htmlFor="phone-number"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														Phone number
													</label>
													<div className="relative mt-2.5">
														<div className="absolute inset-y-0 left-0 flex items-center">
															<label
																htmlFor="country"
																className="sr-only"
															>
																Country
															</label>
															<select
																id="country"
																name="country"
																disabled={loading}
																onChange={countryHandler}
																className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-2 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
															>
																{CEEAC_COUNTRIES.map((country) => (
																	<option
																		value={
																			country.mobileCountryCode
																		}
																	>
																		{country.mobileCountryCode}
																	</option>
																))}
															</select>
															<ChevronDownIcon
																aria-hidden="true"
																className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
															/>
														</div>
														<input
															id="phoneNumber"
															name="phoneNumber"
															value={application.phoneNumber}
															onChange={phoneNumberHandler}
															disabled={loading}
															type="tel"
															autoComplete="tel"
															className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div className="sm:col-span-2">
													<label
														htmlFor="message"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														Educational Background
													</label>
													<div className="mt-2.5">
														<textarea
															id="educationalBackground"
															name="educationalBackground"
															rows={4}
															className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
															defaultValue={""}
															maxLength={MAX_CHARS}
															value={
																application.educationalBackground
															}
															onChange={educationalBackgroundHandler}
															disabled={loading}
														/>
														<p
															id="ebCharCount"
															class="mt-2 text-xs text-gray-500"
														>
															{
																application.educationalBackground
																	.length
															}
															/{MAX_CHARS}
														</p>
													</div>
												</div>
												<div className="sm:col-span-2">
													<label
														htmlFor="message"
														className="block text-sm font-semibold leading-6 text-gray-900"
													>
														Motivation Statement
													</label>
													<div className="mt-2.5">
														<textarea
															id="motivationStatement"
															name="motivationStatement"
															rows={4}
															className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
															defaultValue={""}
															value={application.motivationStatement}
															maxLength={MAX_CHARS}
															onChange={motivationStatementHandler}
															disabled={loading}
														/>
														<p
															id="msCharCount"
															class="mt-2 text-xs text-gray-500"
														>
															{application.motivationStatement.length}
															/{MAX_CHARS}
														</p>
													</div>
												</div>

												<div className="flex items-center sm:col-span-2">
													<button
														type="button"
														className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${
															application.agreed
																? "bg-indigo-600"
																: "bg-gray-200"
														}`}
														role="switch"
														aria-checked={application.agreed}
														aria-labelledby="annual-billing-label"
														onClick={agreedHandler}
														disabled={loading}
													>
														<span
															aria-hidden="true"
															className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
																application.agreed
																	? "translate-x-5"
																	: "translate-x-0"
															}`}
														></span>
													</button>
													<span
														class="ml-3 text-sm"
														id="annual-billing-label"
													>
														<span class="font-medium text-gray-900">
															By selecting this, you agree to
															our&nbsp;
														</span>
														<a
															href="https://akieni.com"
															className="font-semibold text-indigo-600"
														>
															privacy&nbsp;policy
														</a>
													</span>
												</div>

												<div className="sm:col-span-2">
													{errors.length ? (
														<Errors errors={errors} />
													) : null}
													{showCode ? (
														<>
															<hr class="my-8 h-px border-0 bg-gray-200 dark:bg-gray-300" />
															<div class="-mx-3 mb-2 flex flex-wrap pb-2">
																<div class="mb-6 w-full px-3 md:mb-0">
																	<label
																		class="block pb-3 text-sm font-semibold leading-6 text-blue-700"
																		for="grid-city"
																	>
																		Please check your email for
																		the verification code
																	</label>
																	<input
																		id="verificationCode"
																		name="verificationCode"
																		type="text"
																		minLength={6}
																		maxLength={6}
																		pattern=".{6}"
																		value={
																			application.verificationCode
																		}
																		onInput={codeHandler}
																		disabled={disableCode}
																		class="focus:border-white-500 block w-full appearance-none rounded border border-gray-200 bg-gray-100 px-4 py-3 leading-tight text-gray-700 focus:bg-white focus:outline-none md:w-1/6"
																	/>
																</div>
															</div>
														</>
													) : null}
												</div>

												<div className="mt-2 sm:mt-2 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 md:col-span-2">
													<button
														type="button"
														className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
														onClick={handleCancel}
													>
														Cancel
													</button>
													<button
														type="submit"
														disabled={loading || !application.agreed}
														class={`inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${loading ? "cursor-not-allowed opacity-50" : ""}`}
													>
														{loading ? "Loading..." : "Submit"}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

//
//
//

interface ErrorsProps {
	readonly errors: Array<string>;
}

const Errors = ({ errors }: ErrorsProps): h.JSX.Element => {
	return (
		<div className="mt-4 rounded-md bg-red-50 p-4">
			<div className="no-scrollbar flex h-16 overflow-y-auto">
				<div className="flex-shrink-0">
					{/* <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" /> */}
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red-800">
						There were {errors.length} errors with your submission
					</h3>
					<div className="mt-2 text-sm text-red-700">
						<ul role="list" className="list-disc space-y-1 pl-5">
							{errors.map((error, index) => (
								<Fragment key={index}>
									<li>{error}</li>
								</Fragment>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

//
//
//

interface SubmittedMessageProps {
	readonly dimiss: h.JSX.MouseEventHandler<HTMLButtonElement>;
}

const SubmittedMessage = ({ dimiss }: SubmittedMessageProps) => (
	<div class="rounded-md p-4">
		<div class="flex">
			<div class="flex-shrink-0">
				<svg
					class="h-5 w-5 text-blue-400"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
			<div class="ml-3 flex-1 md:flex md:justify-between">
				<p class="text-sm text-blue-700">
					{" "}
					Thank you for your application! We have received it and will be in touch soon.
				</p>
				<p class="mt-3 text-sm md:ml-6 md:mt-0">
					<button
						onClick={dimiss}
						type="button"
						class="ml-3 rounded-md bg-blue-50 px-2 py-1.5 text-sm font-medium text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
					>
						Dismiss
					</button>
				</p>
			</div>
		</div>
	</div>
);
